import { useCallback, useState, useEffect, FunctionComponent } from 'react';
import { Button, Card, Banner, List, Frame, Loading, TextContainer, Layout, Collapsible, Link, ButtonGroup, BlockStack, Text, InlineStack, LegacyStack } from '@shopify/polaris';
import { connect } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import {
  ApplicationState,
  LoadingStatus,
  AppStatusState,
  actionCreators
} from 'store';
import { useToken } from 'integrations/crossborderit';
import { Switch, Case } from 'components/shared';
import { TokenGuard } from './TokenGuard';

type StatusProps =
  AppStatusState
  & typeof actionCreators;

enum ActionStatus {
  Ok,
  ActionRequired
}

const Home: FunctionComponent<StatusProps> = ({
  loadingStatus,
  appStatus,
  requestStatus,
}) => {
  const token = useToken();
  const [scopeAccessStatus, setScopeAccessStatus] = useState(ActionStatus.ActionRequired);
  let [integrationStatus, setIntegrationStatus] = useState(ActionStatus.ActionRequired);
  let [approvalStatus, setApprovalStatus] = useState(ActionStatus.ActionRequired);
  let [euTaxSetupStatus, setEuTaxSetupStatus] = useState(ActionStatus.ActionRequired);

  const updatePermissions = (() => {
    if (top) {
      top.window.location.href = appStatus?.updatePermissionsUrl ?? ""
    }
  })

  const setEuTaxSettingsIsConfirmed = (() => {
    const fetchOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }
    }

    fetch(`api/Account/ConfirmShopifyTaxSetup`, fetchOptions)
      .then(response => response.json())
      .then((data) => {
        euTaxSetupStatus = data.complete
          ? ActionStatus.Ok
          : ActionStatus.ActionRequired
        setEuTaxSetupStatus(euTaxSetupStatus)
      })
  });

  const verifyIntegration = (() => {

    const fetchOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }
    }

    fetch(`api/Account/ConfirmIntegration`, fetchOptions)
      .then(response => response.json())
      .then((data) => {
        integrationStatus = data.complete
          ? ActionStatus.Ok
          : ActionStatus.ActionRequired;
        setIntegrationStatus(integrationStatus);
        console.log('setIntegrationStatus');
      })
  });

  useEffect(() => {
    requestStatus();
  }, [token]);

  useEffect(() => {
    if (loadingStatus !== LoadingStatus.Loaded) return;

    const scopeAccessStatus = appStatus?.updatePermissionsUrl !== null
      ? ActionStatus.ActionRequired
      : ActionStatus.Ok;
    setScopeAccessStatus(scopeAccessStatus);

    let integrationStatus = appStatus?.integrationRequired
      ? ActionStatus.ActionRequired
      : ActionStatus.Ok;
    setIntegrationStatus(integrationStatus);

    let approvalRequired = appStatus?.iossNumber === null
      ? ActionStatus.ActionRequired
      : ActionStatus.Ok;
    setApprovalStatus(approvalRequired);

    let euTaxSetupStatus = appStatus?.shopifyEuTaxSetupConfirmed // && appStatus?.iossNumber !== 'Not approved yet'
      ? ActionStatus.Ok
      : ActionStatus.ActionRequired;
    setEuTaxSetupStatus(euTaxSetupStatus);

  }, [loadingStatus, appStatus]);
  const actionNotRequired =
    scopeAccessStatus === ActionStatus.Ok &&
    integrationStatus == ActionStatus.Ok &&
    euTaxSetupStatus == ActionStatus.Ok

  const [subscriptionIsOpen, setSubscriptionIsOpen] = useState(false);
  const [integrationIsOpen, setIntegrationIsOpen] = useState(false);
  const [euTaxSettingsIsOpen, setEuTaxSettingsIsOpen] = useState(false);

  const handleSubscriptionIsOpenToggle = useCallback(() => setSubscriptionIsOpen((subscriptionIsOpen) => !subscriptionIsOpen), []);
  const handleIntegrationIsOpenToggle = useCallback(() => setIntegrationIsOpen((integrationIsOpen) => !integrationIsOpen), []);
  const handleEuTaxSettingsIsOpenToggle = useCallback(() => setEuTaxSettingsIsOpen((euTaxSettingsIsOpen) => !euTaxSettingsIsOpen), []);

  return (

    <TokenGuard>
      {
        (actionNotRequired &&
          <Navigate to="/dashboard" />)
      }

      {
        (loadingStatus === LoadingStatus.NotInitialized || loadingStatus === LoadingStatus.Loading &&
          <div style={{ height: '100px' }}>
            <Frame>
              <Loading />

              Application is loading...
            </Frame>
          </div>
        )
      }


      {
        ((loadingStatus === LoadingStatus.Loaded && appStatus?.errorMessage !== null) &&
          <Banner
            title={appStatus?.errorMessage}
            tone="warning"
          >
          </Banner>)
      }
      {
              ((loadingStatus === LoadingStatus.Loaded && (appStatus?.errorMessage !== "" || appStatus?.errorMessage.toString().startsWith("No Shop with url") === false) && appStatus?.integrationKey !== undefined) &&

          <div className="setupContent">
            {/* <p>integrationStatus: {ActionStatus[integrationStatus]}</p>
            <p>euTaxSetupStatus: {ActionStatus[euTaxSetupStatus]}</p> */}
            <Banner
              title="Before you can start using this application, this change needs to be made:"
              tone="warning"

            >
              <List type="number">
                {
                  (scopeAccessStatus !== ActionStatus.Ok &&

                    <List.Item>
                      Update application permissions
                    </List.Item>

                  )
                }

                <List.Item>
                  Create an integration between this application and our portal {(integrationStatus === ActionStatus.Ok && <strong> - DONE!</strong>)}
                </List.Item>

                <List.Item>
                  Wait for approval and receive your IOSS number {(approvalStatus === ActionStatus.Ok && <strong> - DONE!</strong>)}
                </List.Item>

                <List.Item>
                  Confirm that your shop is correctly setup for collecting tax for orders into EU  {(euTaxSetupStatus === ActionStatus.Ok && <strong> - DONE!</strong>)}
                </List.Item>

              </List>
            </Banner>

            <br />

            {
              scopeAccessStatus !== ActionStatus.Ok &&
              (
                <Banner title="Update the application permissions" tone='info'>

                  <BlockStack>
                    <Layout>
                      {

                        <Layout.Section>

                          <Card >

                            <Text as="h2" variant="headingSm">
                              Application permissions
                            </Text>

                            <Text as="p">In order to perform all tasks, we have updated the application permissions request that you need to accept before you can continue.</Text>



                            <InlineStack align="end">
                              <ButtonGroup>
                                <Button variant="primary" onClick={() => updatePermissions()} >Update permissions</Button>
                              </ButtonGroup>
                            </InlineStack>




                          </Card>

                        </Layout.Section>

                      }
                    </Layout>

                  </BlockStack>

                </Banner>
              )
            }
            {
              integrationStatus === ActionStatus.Ok
                ?
                <Banner title={'You have completed the integration with our portal'}
                  tone='success'
                />
                :
                <Banner title="Setup integration with Crossborderit"
                  tone="success"
                >
                  <LegacyStack vertical>
                    <Button
                      onClick={handleIntegrationIsOpenToggle}
                      ariaExpanded={integrationIsOpen}
                      ariaControls="basic-collapsible"

                    >
                      {integrationIsOpen ? 'Hide' : 'Show me how'}

                    </Button>
                    <Collapsible
                      open={integrationIsOpen}
                      id="basic-collapsible"
                      transition={{ duration: '500ms', timingFunction: 'ease-in-out' }}
                      expandOnPrint
                    >
                      <Card>

                        Your app is now installed. Now you need to login to your existing Crossborderit account <a href="https://www.crossborderit.com/ioss" target="_blank">HERE</a> or create a new at this <a href="https://cbit.crossborderit.com/merchant-onboarding?referrer=Shopify" target="_blank">LINK</a>.

                        <Text as='h2'>
                          Your app is now installed. Now you need to login to your existing Crossborderit account or create a new at this
                        </Text>
                        <Text as='p' >
                          Copy your IOSS number for later use.<br />
                          In our portal, open the Webshop integrations section under IOSS menu<br />

                        </Text>


                        <img src="images/Integraton-1.jpg" />

                        <Text as='h2'>
                          Insert the Integration key below and select Shopify as the webshop platform and click Create integration
                        </Text>


                        <TextContainer>
                          <Banner title='Integration key' tone='info'>{appStatus?.integrationKey}</Banner>
                        </TextContainer>
                        <br />

                        <img src="images/Integraton-2.jpg" />

                        <Text as='h2'>
                          Verify your settings by clicking the 3-dot menu button on the right
                        </Text>

                        <img src="images/Integraton-3.jpg" />

                        <Text as='h2'>
                          Once If you want to upload IOSS reports automatically, which is optional, select this setting
                        </Text>


                        <img src="images/Integraton-4.jpg" />

                        <Text as='h2'>
                          Now your webshop is integrated with our portal and each night at 00:05 in your shops timezone, a report is uploaded to our portal with all valid fulfilled orders if all these criteria are met</Text>

                        <List>
                          <List.Item>You have been assigned a IOSS number</List.Item>
                          <List.Item>Fulfilled after first your provided IOSS number start date</List.Item>
                          <List.Item>Sold into EU</List.Item>
                          <List.Item>VAT is collected</List.Item>
                          <List.Item>A tracking number is provided</List.Item>
                          <List.Item>Max order value EUR 150 with shipping</List.Item>

                        </List>

                        <Text as='h2'>
                          You can find all reports in the IOSS menu - Uploads
                        </Text>

                        <img src="images/Uploads.jpg" />

                        <InlineStack align="end">
                          <ButtonGroup>
                            <Button onClick={verifyIntegration} accessibilityLabel="Verify integration">
                              Verify integration
                            </Button>

                          </ButtonGroup>
                        </InlineStack>

                      </Card>

                    </Collapsible>
                  </LegacyStack>



                </Banner>
            }



            {
              (integrationStatus === ActionStatus.Ok) &&
              (
                approvalStatus === ActionStatus.Ok
                  ?
                  <Banner title="Account approval"
                    tone="success" >
                    <p>Your account has been approved and your IOSS number to use in the next step is <strong>{appStatus?.iossNumber}</strong></p>
                  </Banner>
                  :

                  <Banner title="Account approval"
                    tone="warning" >
                    <p>Your account is still waiting for approval for using our service. You'll be notified when this is done.</p>
                  </Banner>

              )
            }

            {
              euTaxSetupStatus === ActionStatus.Ok &&
              (
                <Banner title='You have confirmed that your webshop has the correct setting for collecting VAT on orders inot EU'
                  tone="success" />

              )
            }

            {
              (integrationStatus === ActionStatus.Ok && euTaxSetupStatus === ActionStatus.ActionRequired) &&
              (
                approvalStatus === ActionStatus.ActionRequired
                  ?
                  <Banner title="Setup Shopify for collecting VAT on orders into EU"
                    tone="warning" >
                    <p>Your account is still waiting for approval for using our service and you cannot complete the onboarding before this. Please continue the onboarding when you've received the approval confirmation.</p>
                  </Banner>
                  :
                  <Banner title="Setup Shopify for collecting VAT on orders into EU" tone="info">
                    <BlockStack>
                      <Button
                        onClick={handleEuTaxSettingsIsOpenToggle}
                        ariaExpanded={euTaxSettingsIsOpen}
                        ariaControls="basic-collapsible"
                      >
                        {euTaxSettingsIsOpen ? 'Hide' : 'Show me how'}

                      </Button>
                      <Collapsible
                        open={euTaxSettingsIsOpen}
                        id="basic-collapsible"
                        transition={{ duration: '500ms', timingFunction: 'ease-in-out' }}
                        expandOnPrint
                      >
                        <Card>

                          Once your account is approved and you've received the IOSS number, you now need to verify that you have configured Shopify to collect VAT
                          with your IOSS number and make sure it's in Sweden. Also, select which countries you want to collect VAT<br /><br />
                          <List>
                            <List.Item>Open your admin settings in Shopify with this <Link url={appStatus?.shopifyAdminUrl + '/settings/taxes/EU'} external={true} id='shopifyAdmin'>Link</Link> and verify the Tax regions and Tax calculations sections</List.Item>

                          </List>

                          <Text as='h2' >
                            Tax regions
                          </Text>

                          If VAT sales to the EU hasen't already been setup, click Collect Vat link and enter your IOSS number <strong>{appStatus?.iossNumber}</strong> and set Sweden as country.<br />

                          <img src="images/ShopifyEuTaxesSettingNotSet.jpg" /><br />
                          <br />


                          It should look like this in the Ship to the EU section but with your IOSS number <strong>{appStatus?.iossNumber}</strong><br />
                          If this is not yet configured, click the Collect VAT link<br />
                          <img src="images/ShopifyEuTaxesSetting.jpg" />

                          <Text as='h2'>
                            Tax calculations
                          </Text>

                          <Banner tone="warning">
                            It's really important that these settings are correct, it will affect your earnings!
                          </Banner>
                          <br />
                          Review your setting so these are correct according to your pricing. If all prices are including taxes, VAT will be drawn from the prices shown on checkout. Otherwise, taxes are calculated based on the prices and added to the checkout amount.<br />


                          <Button></Button>

                          <InlineStack align="end">
                            <ButtonGroup>
                              <Button variant="primary" onClick={setEuTaxSettingsIsConfirmed} >I confirm that we have done this</Button>
                            </ButtonGroup>
                          </InlineStack>


                        </Card>

                      </Collapsible>
                    </BlockStack>



                  </Banner>
              )
            }
          </div>
        )

      }


    </TokenGuard >

  )
}


// class Home extends React.PureComponent<StatusProps> {
//   public render() {
//
//     // if (this.props.appStatus?.upgradeRequired)
//       return ();
//
//     // if (!this.props.appStatus?.integrationCompleted)
//     //   return (
//     //     <React.Fragment>
//     //       <div>{this.props.appStatus}</div>
//     //       <h1 id="tabelLabel">No integration with CBIT portal exist</h1>
//     //       <p>Follow the instructions</p>
//     //       {/* {this.renderCreateIntegration()} */}
//     //     </React.Fragment>
//     );
//   }
// }

export default connect(
  (state: ApplicationState) => state.home, // Selects which state properties are merged into the component's props
  actionCreators // Selects which action creators are merged into the component's props
)(Home as any); // eslint-disable-line @typescript-eslint/no-explicit-any
